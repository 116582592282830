.markdown {
    margin: 2em;
    font-family: "Yu-Gothic-UI";
    font-size: 0.9em;
}

.markdown p {
    margin-bottom: 1em;
}

.markdown h1 {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 2em;
    margin-bottom: 1em;
    
	/* color: #333; */
	/* background: #EDF2F7;
	border-radius: 10px;
	padding: 0.75rem 2rem 0.5rem 2rem; */
}

.chakra-ui-dark .markdown h1 {
	background: #333;
}

.markdown h2 {
    font-size: 1.3em;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 1em;
    
    border-bottom: solid 3px #333;
}

.markdown h3 {
    font-size: 1.1em;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 0.5em;
    
    /* background: linear-gradient(transparent 60%, #c8f1ff 60%); */
}

.markdown h4 {
    font-size: 1em;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.markdown a {
    text-decoration: underline;
}

.markdown ul {
    margin: 1em;
}

.markdown ol {
    margin: 1em;
}

.markdown blockquote {
    margin: 1em;
    color: gray;
}

.markdown pre {
    margin: 8px;
    padding: 8px;
    color: white;
    background-color: darkslategrey;
	border-radius: 5px;
}

.markdown table {
    border: solid 1px #333;
    margin: 8px;
}

.markdown th {
    border: solid 1px #333;
    padding: 4px;
}

.markdown td {
    border: solid 1px #333;
    padding: 8px;
}